import { Events } from '@cjconnect/util-common'
import MobileDetect from 'mobile-detect'

/**
 * getToken
 * accessToken을 얻어온다 (웹/앱)
 */
export function getToken() {
  const md = new MobileDetect(window.navigator.userAgent, 1193)

  // Android, iOS 등 모바일 기기일 경우에만 수행
  if (md.os()) {
    const getTokenCallback = (accessToken: string, _refreshToken: string) => {
      if (accessToken) {
        window.sessionStorage.setItem('accessToken', accessToken)
        setTimeout(() => Events.emit('fetcher/RECEIVED_TOKEN', accessToken), 250)
      }
    }

    // call native interface
    if (window.FlutterChannel) {
      // APP 콜백 메소드
      window.receiveDataFromFlutter = getTokenCallback
      window.FlutterChannel.postMessage('getToken') // requests access token
    } else {
      window.console.error('FlutterChannel is not available!\n\n')
      // 로컬개발환경에서 테스트시 sessionStorage에 "accessToken" 키로 토큰 등록하면 됨
      // accessToken 생성은 README.md 파일 참고
      getTokenCallback(window.sessionStorage.getItem('accessToken') ?? '', '')
    }
  }
}

/**
 * closeWebview (웹/앱)
 */
export function goBackOrCloseWebview() {
  let hasHistory = false

  window.addEventListener('beforeunload', () => (hasHistory = true), { once: true })
  window.history.back()

  setTimeout(() => {
    if (!hasHistory || window.history.length <= 1) {
      // call native interface
      if (window.FlutterChannel) {
        window.FlutterChannel.postMessage('close')
      } else if (window.top) {
        window.top.opener = self
        window.top.close()
      } else {
        window.opener = self
        window.close()
      }
    }
  }, 250)
}
