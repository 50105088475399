import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import React, { useRef, useEffect } from 'react'
import EventProvider from '@/providers/EventProvider'
import { FetcherProvider } from '@/providers/FetcherContext'
import ReactQueryProvider from '@/providers/ReactQueryProvider'
import RouteChangeProvider from '@/providers/RouteChangeProvider'
import GTM from '@/utils/GTM'
import { getToken } from '@/utils/mobile'
import '@/assets/styles/reset.scss'
import '@/assets/styles/common.scss'

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const isRequestAccessToken = useRef<boolean>(false)
  const canonicalPath = `${process.env.NEXT_PUBLIC_UNBAN_WEB_URL}${router.asPath}`

  useEffect(() => {
    GTM.initialize()
    if (!isRequestAccessToken.current) {
      isRequestAccessToken.current = true
      getToken() // APP단에서 accessToken 정보를 가져온다
    }
  }, [])

  useEffect(() => {
    const url = router.pathname
    const pathname = url.startsWith('/') ? url : new URL(url).pathname
    GTM.sendCustomEvent({ event: 'page_view', page_path: pathname }) // "page_location" 아님
  }, [router.pathname])

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta httpEquiv='Last-Modified' content={process.env.APP_BUILD_GMT} />
        <meta name='application-name' content={process.env.NEXT_PUBLIC_APP_TITLE} />
        <meta name='description' content={process.env.NEXT_PUBLIC_APP_DESC} />
        <meta name='author' content={process.env.NEXT_PUBLIC_APP_AUTHOR} />
        <meta name='keywords' content={process.env.NEXT_PUBLIC_APP_KEYWORDS} />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,shrink-to-fit=no'
        />
        <meta name='referrer' content='no-referrer-when-downgrade' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='format-detection' content='telephone=no,address=no' />
        <meta name='theme-color' content='#ffffff' id='theme-color' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='#ffffff' />
        <meta name='apple-mobile-web-app-title' content={process.env.NEXT_PUBLIC_APP_TITLE} />
        {/* Site verifications */}
        <meta name='google-site-verification' content='xOFAmSg9ZDMmP4f6UvYFNcuaHzzromYkRfUewAugK1Y' />
        <meta name='naver-site-verification' content='b451617ac9079ac6811ff343aa861a5ebb4e1a1b' />
        {/* Favicon */}
        <link rel='apple-touch-icon' href={`${process.env.NEXT_PUBLIC_CF_COMMON_PWA_URL}/256.png`} type='image/png' />
        <link
          rel='icon'
          href={`${process.env.NEXT_PUBLIC_CF_COMMON_PWA_URL}/favicon_32.png`}
          type='image/png'
          sizes='32x32'
        />
        <link
          rel='icon'
          href={`${process.env.NEXT_PUBLIC_CF_COMMON_PWA_URL}/favicon_64.png`}
          type='image/png'
          sizes='64x64'
        />
        <link
          rel='shortcut icon'
          href={`${process.env.NEXT_PUBLIC_CF_COMMON_PWA_URL}/favicon.ico`}
          type='image/x-icon'
          sizes='310x310'
        />
        {/* PWA */}
        <link rel='manifest' href={`${process.env.NEXT_PUBLIC_UNBAN_WEB_URL}/manifest.json`} />
        {/* Pre-connects and DNS-prefetches */}
        <link rel='preconnect' href='https://www.googletagmanager.com' />
        <link rel='dns-prefetch' href='https://www.google-analytics.com' />
        <link rel='dns-prefetch' href='https://analytics.google.com' />
        <link rel='dns-prefetch' href='https://www.googletagmanager.com' />
        <link rel='dns-prefetch' href='https://www.gstatic.com' />
        <link rel='dns-prefetch' href='https://marketingplatform.google.com' />
        <link rel='dns-prefetch' href='https://fastly.jsdelivr.net' />
        <link rel='dns-prefetch' href='https://i.ytimg.com' />
        <link rel='dns-prefetch' href='https://yt3.ggpht.com' />
        <link rel='dns-prefetch' href='https://t1.daumcdn.net' />
        <link rel='dns-prefetch' href='https://static.doubleclick.net' />
        <link rel='dns-prefetch' href='https://www.youtube.com' />
      </Head>

      <DefaultSeo
        title={process.env.NEXT_PUBLIC_APP_TITLE}
        description={process.env.NEXT_PUBLIC_APP_DESC}
        canonical={canonicalPath}
        mobileAlternate={{
          media: 'only screen and (max-width: 720px)',
          href: canonicalPath,
        }}
        openGraph={{
          type: 'website',
          locale: 'ko_KR',
          title: process.env.NEXT_PUBLIC_APP_TITLE,
          description: process.env.NEXT_PUBLIC_APP_DESC,
          url: canonicalPath,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_CF_COMMON_URL}/unban-og-image.jpg`,
              width: 1200,
              height: 630,
              alt: '더 운반 이미지',
              type: 'image/jpeg',
            },
          ],
          siteName: process.env.NEXT_PUBLIC_APP_TITLE,
        }}
      />

      <EventProvider>
        <FetcherProvider>
          <ReactQueryProvider state={pageProps.dehydratedState}>
            <RouteChangeProvider>
              <Component {...pageProps} />
            </RouteChangeProvider>
          </ReactQueryProvider>
        </FetcherProvider>
      </EventProvider>
    </>
  )
}

export default appWithTranslation(App)
